import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import texts from './texts';
import placeholderImage from './vivero-cell-in-hand.png';
import plantHand from './vivero-plants-in-hand.png';
import herbGarden from './herb-garden.png';
import './App.css';

function App() {
  const [language, setLanguage] = useState('es');

  const handleLanguageToggle = () => {
    setLanguage(language === 'en' ? 'es' : 'en');
  };

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={
            <div className="main-view">
              <button onClick={handleLanguageToggle} className="language-toggle">
                {language === 'en' ? 'ES' : 'EN'}
              </button>
              <h1 className="main-title">{texts[language].title}</h1>
              <a href="https://app.vivero.xyz" className="app-link-button">
                {texts[language].appButton}
              </a>
              
              <div className="content-wrapper">
                <div className="text-content">
                  <h2 className="big-title">{texts[language].sexyTitle}</h2>
                  <p className="description">{texts[language].sexyParagraph}</p>
                </div>
                <img src={placeholderImage} alt="Placeholder" className="placeholder-image" />
              </div>
              
              <div className="content-wrapper-reverse">
                <img src={plantHand} alt="PlantHand" className="placeholder-image" />
                <div className="text-content">
                  <h2 className="big-title">{texts[language].forUsersTitle}</h2>
                  <p className="description"><strong>{texts[language].forUsersParagraph}</strong></p>
                  <ul className="bullet-list">
                    <li>{texts[language].usersBullet1}</li>
                    <li>{texts[language].usersBullet2}</li>
                  </ul>
                </div>
              </div>
              
              <div className="content-wrapper">
                <div className="text-content">
                  <h2 className="big-title">{texts[language].forStoresTitle}</h2>
                  <p className="description"><strong>{texts[language].forStoresParagraph}</strong></p>
                  <ul className="bullet-list">
                    <li>{texts[language].storesBullet1}</li>
                    <li>{texts[language].storesBullet2}</li>
                  </ul>
                </div>
                <img src={herbGarden} alt="PlantMarket" className="placeholder-image" />
              </div>
            </div>
          } />
        </Routes>
      </Router>
      <footer className="footer">
        <p className="footer-text">{texts[language].footerContact}</p>
      </footer>
    </div>
  );
}

export default App;
