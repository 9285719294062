// texts.js
const texts = {
    en: {
      title: "Vivero",
      appButton: "Launch App",
      sexyTitle: "The whole plant community in one app",
      sexyParagraph: "Connect with plant-parents and Viveros near you to achieve your dreamed plant-home.",
      forUsersTitle: "A proactive plant-care community",
      forUsersParagraph: "As a User, connect with Viveros and plant-parents alike to get:",
      usersBullet1: "Caring tips catered to the species you own.",
      usersBullet2: "Recommendations/offers of plants and supplies to get your dreamed plant-space started.",
      forStoresTitle: "Spotlight your marketplace",
      forStoresParagraph: "As a Vivero, access to a wide community of buyers by setting up your marketplace in two steps:",
      storesBullet1: "Set up your basic info (location and payment).",
      storesBullet2: "Take a picture of the plant for sale and you're selling!",
      footerContact: "Mail us: contact@vivero.xyz"
    },
    es: {
      title: "Vivero",
      appButton: "Lanzar Aplicación",
      sexyTitle: "Toda la comunidad de plantas en una sola aplicación",
      sexyParagraph: "Conéctate con padres de plantas y Viveros cerca de ti para lograr el hogar de plantas de tus sueños.",
      forUsersTitle: "Una comunidad proactiva en el cuidado de plantas",
      forUsersParagraph: "Como Usuario, conéctate con Viveros y padres de plantas para obtener:",
      usersBullet1: "Consejos de cuidado adaptados a las especies que posees.",
      usersBullet2: "Recomendaciones/ofertas de plantas y suministros para comenzar tu espacio de plantas soñado.",
      forStoresTitle: "Destaca tu mercado",
      forStoresParagraph: "Como Vivero, accede a una amplia comunidad de compradores configurando tu mercado en dos pasos:",
      storesBullet1: "Configura tu información básica (ubicación y pago).",
      storesBullet2: "¡Toma una foto de la planta a la venta y estás vendiendo!",
      footerContact: "Envíanos un correo: contact@vivero.xyz"
    }
  };
  
  export default texts;
  